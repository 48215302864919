import * as grpc from "grpc-web";
import {
	projectJsonToClass,
	taskJsonToClass,
	eventJsonToClass,
	listJsonToClass,
} from "./CalendarUtils";
import {
	projectService,
	listService,
	taskService,
	calendarLabelsService,
	eventService,
} from "../globalClient/GlobalClients";
import {
	ReadCalendarLabelsResponse,
	ReadCalendarLabelsRequest,
} from "../pb/calendar_label_pb";
import {
	CreateEventResponse,
	CreateEventRequest,
	UpdateEventResponse,
	UpdateEventRequest,
	ReadEventResponse,
	ReadEventRequest,
	ReadEventsResponse,
	ReadEventsRequest,
	DeleteEventResponse,
	DeleteEventRequest,
	Event,
} from "../pb/event_pb";
import {
	ReadListsByProjectIdResponse,
	ReadListsByProjectIdRequest,
	CreateListResponse,
	CreateListRequest,
	List,
} from "../pb/list_pb";
import {
	Project,
	CreateProjectResponse,
	CreateProjectRequest,
	ReadProjectsResponse,
	ReadProjectsRequest,
} from "../pb/project_pb";
import {
	ReadTasksResponse,
	ReadTasksRequest,
	ReadTaskResponse,
	ReadTaskRequest,
	ReadTasksByListIdRequest,
	DeleteTaskResponse,
	DeleteTaskRequest,
	Task,
	CreateTaskResponse,
	CreateTaskRequest,
	UpdateTaskRequest,
	UpdateTaskListResponse,
	UpdateTaskListRequest,
	UpdateTaskResponse,
} from "../pb/task_pb";

interface PaginationOptions {
	page: number;
	perPage: number;
}

const requestHeader = () => {
	return {
		"Content-Type": "application/grpc-web+proto",
		Authorization: localStorage.getItem("accessToken") ?? "",
	};
};

export const createProject = async (
	data: Project.AsObject
): Promise<CreateProjectResponse.AsObject> => {
	const request = new CreateProjectRequest();
	request.setProject(projectJsonToClass(data));
	return new Promise((resolve, reject) => {
		projectService.createProject(
			request,
			requestHeader(),
			(err: grpc.RpcError, response: CreateProjectResponse) => {
				if (err) {
					reject(err);
				} else {
					resolve(response.toObject());
				}
			}
		);
	});
};

export const readProjects = async (
	{ page, perPage }: PaginationOptions = { page: 1, perPage: 100 }
): Promise<ReadProjectsResponse.AsObject> => {
	const request = new ReadProjectsRequest();
	request.setPage(page);
	request.setPerpage(perPage);

	return new Promise((resolve, reject) => {
		projectService.readProjects(
			request,
			requestHeader(),
			(err: grpc.RpcError, response: ReadProjectsResponse) => {
				if (err) {
					reject(err);
				} else {
					resolve(response.toObject());
				}
			}
		);
	});
};

export const readListsByProjectId = async (
	projectId: string,
	{ page, perPage }: PaginationOptions = { page: 1, perPage: 100 }
): Promise<ReadListsByProjectIdResponse.AsObject> => {
	const request = new ReadListsByProjectIdRequest();
	request.setProjectId(projectId);
	request.setPage(page);
	request.setPerPage(perPage);

	return new Promise((resolve, reject) => {
		listService.readListsByProjectId(
			request,
			requestHeader(),
			(err: grpc.RpcError, response: ReadListsByProjectIdResponse) => {
				if (err) {
					reject(err);
				} else {
					resolve(response.toObject());
				}
			}
		);
	});
};

export const readTasks = async (): Promise<ReadTasksResponse.AsObject> => {
	const request = new ReadTasksRequest();
	return new Promise((resolve, reject) => {
		taskService.readTasks(
			request,
			requestHeader(),
			(err: grpc.RpcError, response: ReadTasksResponse) => {
				if (err) {
					reject(err);
				} else {
					resolve(response.toObject());
				}
			}
		);
	});
};

export const readTask = async (
	id: string
): Promise<ReadTaskResponse.AsObject> => {
	const request = new ReadTaskRequest();
	request.setId(id);

	return new Promise((resolve, reject) => {
		taskService.readTask(
			request,
			requestHeader(),
			(err: grpc.RpcError, response) => {
				if (err) {
					reject(err);
				} else {
					resolve(response.toObject());
				}
			}
		);
	});
};

export const readTasksByListId = async (
	listId: string,
	{ page, perPage }: PaginationOptions = { page: 1, perPage: 100 }
) => {
	const request = new ReadTasksByListIdRequest();
	request.setListId(listId);
	request.setPage(page);
	request.setPerPage(perPage);

	return new Promise((resolve, reject) => {
		taskService.readTasksByListId(
			request,
			requestHeader(),
			(err: grpc.RpcError, response) => {
				if (err) {
					reject(err);
				} else {
					resolve(response.toObject());
				}
			}
		);
	});
};

export const deleteTask = async (
	taskId: string
): Promise<DeleteTaskResponse.AsObject> => {
	return new Promise((resolve, reject) => {
		taskService.deleteTask(
			new DeleteTaskRequest().setId(taskId),
			requestHeader(),
			(err: grpc.RpcError, response) => {
				if (err) {
					reject(err);
				} else {
					resolve(response.toObject());
				}
			}
		);
	});
};

export const createTask = async (
	data: Task.AsObject
): Promise<CreateTaskResponse.AsObject> => {
	const request = new CreateTaskRequest();
	request.setTask(taskJsonToClass(data));

	return new Promise((resolve, reject) => {
		taskService.createTask(
			request,
			requestHeader(),
			(err: grpc.RpcError, response) => {
				if (err) {
					reject(err);
				} else {
					resolve(response.toObject());
				}
			}
		);
	});
};

export const updateTask = async (
	data: Task.AsObject
): Promise<UpdateTaskResponse.AsObject> => {
	const request = new UpdateTaskRequest();
	request.setTask(taskJsonToClass(data));

	return new Promise((resolve, reject) => {
		taskService.updateTask(
			request,
			requestHeader(),
			(err: grpc.RpcError, response) => {
				if (err) {
					reject(err);
				} else {
					resolve(response.toObject());
				}
			}
		);
	});
};

export const updateTaskList = async (data: {
	taskId: string;
	listId: string;
}): Promise<UpdateTaskListResponse.AsObject> => {
	const request = new UpdateTaskListRequest();
	request.setTaskId(data.taskId);
	request.setListId(data.listId);

	return new Promise((resolve, reject) => {
		taskService.updateTaskList(
			request,
			requestHeader(),
			(err: grpc.RpcError, response) => {
				if (err) {
					reject(err);
				} else {
					resolve(response.toObject());
				}
			}
		);
	});
};

export const readCalendarLabels = async (
	{ page, perPage }: PaginationOptions = { page: 1, perPage: 100 }
): Promise<ReadCalendarLabelsResponse.AsObject> => {
	const request = new ReadCalendarLabelsRequest();
	request.setPage(page);
	request.setPerPage(perPage);

	return new Promise((resolve, reject) => {
		calendarLabelsService.readCalendarLabels(
			request,
			requestHeader(),
			(err: grpc.RpcError, response) => {
				if (err) {
					reject(err);
				} else {
					resolve(response.toObject());
				}
			}
		);
	});
};

export const createEvent = async (
	data: Event.AsObject
): Promise<CreateEventResponse.AsObject> => {
	const request = new CreateEventRequest();
	request.setEvent(eventJsonToClass(data));

	return new Promise((resolve, reject) => {
		eventService.createEvent(
			request,
			requestHeader(),
			(err: grpc.RpcError, response) => {
				if (err) {
					reject(err);
				} else {
					resolve(response.toObject());
				}
			}
		);
	});
};

export const updateEvent = async (
	data: Event.AsObject
): Promise<UpdateEventResponse.AsObject> => {
	const request = new UpdateEventRequest();
	request.setEvent(eventJsonToClass(data));

	return new Promise((resolve, reject) => {
		eventService.updateEvent(
			request,
			requestHeader(),
			(err: grpc.RpcError, response) => {
				if (err) {
					reject(err);
				} else {
					resolve(response.toObject());
				}
			}
		);
	});
};

export const readEvent = async (
	eventId: string
): Promise<ReadEventResponse.AsObject> => {
	const request = new ReadEventRequest();
	request.setId(eventId);

	return new Promise((resolve, reject) => {
		eventService.readEvent(
			request,
			requestHeader(),
			(err: grpc.RpcError, response) => {
				if (err) {
					reject(err);
				} else {
					resolve(response.toObject());
				}
			}
		);
	});
};

export const readEvents = async (): Promise<ReadEventsResponse.AsObject> => {
	const request = new ReadEventsRequest();

	return new Promise((resolve, reject) => {
		eventService.readEvents(
			request,
			requestHeader(),
			(err: grpc.RpcError, response) => {
				if (err) {
					reject(err);
				} else {
					resolve(response.toObject());
				}
			}
		);
	});
};

export const deleteEvent = async (
	eventId: string
): Promise<DeleteEventResponse.AsObject> => {
	const request = new DeleteEventRequest();
	request.setId(eventId);

	return new Promise((resolve, reject) => {
		eventService.deleteEvent(
			request,
			requestHeader(),
			(err: grpc.RpcError, response) => {
				if (err) {
					reject(err);
				} else {
					resolve(response.toObject());
				}
			}
		);
	});
};

export const createList = async (
	data: List.AsObject
): Promise<CreateListResponse.AsObject> => {
	const request = new CreateListRequest();
	request.setList(listJsonToClass(data));

	return new Promise((resolve, reject) => {
		listService.createList(
			request,
			requestHeader(),
			(err: grpc.RpcError, response) => {
				if (err) {
					reject(err);
				} else {
					resolve(response.toObject());
				}
			}
		);
	});
};
