import { copyTextToClipboard, notify } from '../../gateway/Functions';
import CustomCopyButton from '../buttons/CustomCopyButton';

interface Props {
    text: string;
    title: string;
    successCallback?: () => void;
}
export default function CopyToClipBoard({ text, title, successCallback }: Props) {
    const handleOnClick = () => {
        copyTextToClipboard(text)
            .then(() => {
                notify(
                    "success",
                    "bottomLeft",
                    "Success",
                    "Copied to ClipBoard"
                )
                successCallback && successCallback();
            })
    }
    return (
        <CustomCopyButton tooltipLabel={title} onClick={handleOnClick} />
    )
}

