import { useEffect, useMemo, useState } from "react";
import MyTable from "../../MyTable";
import { Box, Flex, Tag, useDisclosure, VStack } from "@chakra-ui/react";
import { MdTaskAlt, MdViewList, MdViewModule } from "react-icons/md";
import { readListsByProjectId, readTasksByListId } from "../../../gateway/gRPC/Utils/CalendarMethods";
import moment from "moment";
import { BiCalendarStar } from "react-icons/bi";
import { DatePicker } from "antd";
import CustomViewButton from "../../buttons/CustomViewButton";
import { Outlet } from "react-router-dom";
import AddTask from "../tasks/AddTask";
import IconButton from "../../buttons/IconButton";
import strings from "../../../localization/main";
import ListBoardView from "./ListBoardView";
import { useSelector } from "react-redux";
import { Task } from "../../../gateway/gRPC/pb/task_pb";
import TaskModal from "../tasks/TaskModal";
import CustomButton from "../../buttons/CustomButton";

interface ListTableProps {
    projectId: string
}

const ListsTableView = ({ projectId }: ListTableProps) => {
    const [data, setData] = useState<any[]>([])
    const [page, setPage] = useState(1)
    const [loading, setLoading] = useState(false)
    const [list, setList] = useState<any[]>([])
    const update = useSelector((state: any) => state.UpdateTables);


    const loadNextPage = () => {
        setPage(page + 1)
    }

    const {
        isOpen: viewIsOpen,
        onOpen: viewOnOpen,
        onClose: viewOnClose,
    } = useDisclosure();

    const columns = useMemo(() => {
        return [
            {
                title: "",
                dataIndex: "id",
                key: "icon",
                align: "center",
                render: (_: any) => (
                    <Box>
                        <MdTaskAlt />
                    </Box>
                ),
            },
            {
                title: "Title",
                dataIndex: "title",
                key: "title",
            },
            {
                title: "List",
                dataIndex: "listId",
                key: "listId",
                render: (listId: any) => (
                    <Tag
                        borderRadius={"3px"}
                        colorScheme={"blue"}
                        size={"xs"}
                        p={".2rem .5rem"}>
                        {list.find((l) => l.id === listId)?.title}
                    </Tag>

                )
            },
            {
                title: "Due Date",
                dataIndex: "dueDate",
                key: "dueDate",
                render: (dueDate: any) => (
                    <Tag
                        borderRadius={"3px"}
                        colorScheme={
                            moment(dueDate).isBefore(new Date()) ? "red" : "green"
                        }
                        size={"xs"}
                        p={".2rem .5rem"}
                    >
                        <Flex gap={".3rem"} alignItems={"center"}>
                            <BiCalendarStar />
                            {`${moment(dueDate).format("MMM DD, YYYY - hh:mm A")}`}
                        </Flex>
                    </Tag>
                ),
                filterDropdown: ({ setSelectedKeys, confirm }: any) => (
                    <div style={{ padding: 8 }}>
                        <DatePicker.RangePicker
                            style={{ width: 200 }}
                            onChange={(dates, dateStrings) => {
                                setSelectedKeys([dateStrings]);
                                confirm();
                            }}
                        />
                    </div>
                ),
                onFilter: (value: any, record: any) => {
                    if (!moment(value[0]).isValid() && !moment(value[1]).isValid()) {
                        return true;
                    }
                    return moment(record?.dueDate).isBetween(value[0], value[1]);
                },
            },
            {
                title: "Labels",
                dataIndex: "labelsList",
                key: "labelsList",
                align: "center",
                render: (_: any, record: Task.AsObject) => (
                    <Flex>
                        {record?.labelsList?.map((label: any) => (
                            <Tag key={label?.id} colorScheme={"blue"} size={"xs"} p={".2rem .5rem"}>
                                {label?.title}
                            </Tag>
                        ))}
                    </Flex>
                ),
                // filters: labelsFilters?.map((label: any) => {
                // 	return {
                // 		text: label?.name,
                // 		value: label?.id,
                // 	};
                // }),
                // onFilter: (value: any) => setLabelFilterValue(value), // TODO: filter by label
            },
            {
                title: "Actions",
                dataIndex: "id",
                key: "id",
                width: 50,
                align: "center",
                render: (_: any, record: Task.AsObject) => (
                    <TaskModal task={record} tableView={true} />
                ),
            },
        ]
    }, [list])

    const readTasks = async (listId: string) => {
        return readTasksByListId(listId, { page: 1, perPage: 10 })
            .then((res: any) => {
                if (res.tasksList.length > 0) {
                    return res.tasksList;
                }
                return [];
            })
            .catch((err) => {
                console.log(err)
            })
    }

    useEffect(() => {
        setLoading(true)
        readListsByProjectId(projectId, { page: page, perPage: 10 })
            .then((res) => {
                setList(res.listsList)
                return Promise.all(res.listsList.map((list: any) => readTasks(list.id)))
            })
            .then((tasksLists) => {
                setData(tasksLists.flat());
            })
            .catch((err) => {
                console.log(err)
            })
            .finally(() => {
                console.log(data)
                setLoading(false)
            })

    }, [projectId, page, update])

    return (
        <Flex
            justifyContent={"space-between"}
            alignItems={"flex-start"}
            gap={".5rem"}
        >

            <VStack flexGrow={1} maxW={"100%"} overflow={"scroll"}>
                <Flex justifyContent={"flex-end"} p={".2rem"} w={"100%"}>
                    {viewIsOpen ? (
                        <IconButton
                            icon={<MdViewModule />}
                            title={strings.table_view}
                            onClick={viewOnClose}
                        />
                    ) : (
                        <IconButton
                            icon={<MdViewList />}
                            title={strings.board_view}
                            onClick={viewOnOpen}
                        />
                    )}
                </Flex>

                <Flex justifyContent={"flex-start"} p={".2rem"} w={"100%"}>
                    {!viewIsOpen ? (
                        <MyTable
                            action={
                                <AddTask fromTableView={true} projectId={projectId} />
                            }
                            title={"Tasks"}
                            columns={columns}
                            data={data}
                            loadNextPage={loadNextPage}
                            loading={loading}
                        />
                    ) : (<ListBoardView tasks={data} lists={list} />)}
                </Flex>

            </VStack>
            <Outlet />
        </Flex>
    )
}

export default ListsTableView;
