import { Button, HStack, Text, Tooltip, useDisclosure } from "@chakra-ui/react";
import MyModal from "../general/MyModal";
import CopyToClipBoard from "../general/CopyToClipBoard";
import { BiSolidShareAlt } from "react-icons/bi";

interface ShareButtonProps {
  tooltipLabel?: string;
  hasArrow?: boolean;
  [key: string]: any;
}
function CustomShareButton({
  tooltipLabel,
  placement,
  hasArrow,
  ...rest
}: ShareButtonProps) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <>
      <Tooltip label={tooltipLabel} placement={placement} hasArrow={hasArrow}>
        <Button
          placeItems={"center"}
          bg={"gray.200"}
          borderRadius={"3px"}
          _hover={{ bg: "gray.300" }}
          color={"gray.700"}
          size={"xs"}
          {...rest}
          onClick={onOpen}
        >
          <BiSolidShareAlt />
        </Button>
      </Tooltip>
      <MyModal
        title={rest.title}
        disclosure={{
          isOpen,
          onOpen,
          onClose,
        }}
        size="xl"
      >
        <Text textTransform={"capitalize"}>{"share link"}</Text>
        <HStack
          w={"100%"}
          bg={"gray.100"}
          p={".5rem"}
          mt={"1rem"}
          mb={"3rem"}
          borderRadius={"md"}
          justify={"space-between"}
        >
          <Text>{rest.link}</Text>
          <CopyToClipBoard
            text={rest.link}
            title={"Copy Link"}
            successCallback={onClose}
          />
        </HStack>
      </MyModal>
    </>
  );
}

export default CustomShareButton;
