import { lazy } from "react";
import Departments from "../components/settings/organization/Departments";
import Surveys from "../components/surveys/Surveys";
import ViewQuestionnaire from "../components/surveys/ViewQuestionnaire";
import CreateQuestionnaire from "../components/surveys/questionnaire/CreateQuestionnaire";
import SurveyResponse from "../components/surveys/responses/SurveyResponse";
import SurveySubmitted from "../components/surveys/responses/SurveySubmitted";
import strings from "../localization/main";
import Redirect from "../pages/Redirect";
import { RouteDataType } from "../types/DataTypes";
import ProjectsView from "../components/calendar/projects/ProjectsView";

const TicketingReports = lazy(
  () => import("../components/chatDesk/TicketingReports")
);
const UserProfile = lazy(
  () => import("../components/userManagement/UserProfile")
);
const PermissionsProvider = lazy(
  () => import("../components/auth/PermissionsProvider")
);
const ViewRole = lazy(
  () => import("../components/settings/components/roles/ViewRole")
);
const Navigate = lazy(() =>
  import("react-router-dom").then((module) => ({ default: module.Navigate }))
);
const WalletContextProvider = lazy(
  () => import("../components/wallet/WalletContext")
);
const CmCsat = lazy(() => import("../components/callModule/CSAT/CmCsat"));
const ViewSurvey = lazy(
  () => import("../components/callModule/CSAT/ViewSurvey")
);
const ViewContact = lazy(() => import("../components/newContact/ViewContact"));
const MyCalendar = lazy(() => import("../components/calendar/MyCalendar"));
const AccountEmails = lazy(() => import("../components/crm/AccountEmails"));
const TasksTable = lazy(() => import("../components/crm/tables/TasksTable"));
const Apps = lazy(() => import("../components/apps/Apps"));
const ContactsTable = lazy(
  () => import("../components/crm/tables/ContactsTable")
);
const ViewTask = lazy(() => import("../components/crm/tasks/ViewTask"));
const LeadsTable = lazy(() => import("../components/crm/tables/LeadsTable"));
const ViewLead = lazy(() => import("../components/crm/crmCrud/leads/ViewLead"));
const DealsTable = lazy(() => import("../components/crm/tables/DealsTable"));
const Campaigns = lazy(() => import("../components/campaigns/Campaigns"));
const Commerce = lazy(() => import("../pages/Commerce"));
const SummaryReports = lazy(
  () => import("../components/live-reports/SummaryReport")
);
const AgentReport = lazy(
  () => import("../components/live-reports/AgentReport")
);
const VoiceReport = lazy(
  () => import("../components/live-reports/VoiceReport")
);

const AutoDialer = lazy(
  () => import("../components/campaigns/AutoDialer/AutoDialer")
);
const RobotCall = lazy(
  () => import("../components/campaigns/RobotCall/RobotCall")
);
const Sms = lazy(() => import("../components/campaigns/Sms/Sms"));

const ViewWhatsAppCampaign = lazy(
  () =>
    import("../components/campaigns/WhatsApp/Campaigns/ViewWhatsappCampaign")
);
const ViewDialCampaign = lazy(
  () => import("../components/campaigns/AutoDialer/ViewDialCampaign")
);
const ViewSmsCampaign = lazy(
  () => import("../components/campaigns/Sms/campaign/ViewSmsCampaign")
);
const ViewRobotCampaign = lazy(
  () => import("../components/campaigns/RobotCall/ViewRobotCall")
);
const Crm = lazy(() => import("../pages/Crm"));
const ChatDesk = lazy(() => import("../pages/ChatDesk"));
const TicketingAnalytics = lazy(
  () => import("../components/chatDesk/TicketingAnalytics")
);
const TicketingHelpDesk = lazy(
  () => import("../components/chatDesk/TicketingHelpDesk")
);
const TicketDetails = lazy(
  () => import("../components/chatDesk/ticketingCrud/Ticket/TicketDetails")
);
const CrmOverview = lazy(() => import("../components/crm/CrmOverview"));
const CrmAnalytics = lazy(() => import("../components/crm/CrmAnalytics"));
const CrmDetails = lazy(() => import("../components/crm/crmCrud/CrmDetails"));
const ProfileSettings = lazy(
  () => import("../components/settings/personal/ProfileSettings")
);
const PasswordSettings = lazy(
  () => import("../components/settings/personal/PasswordSettings")
);
const CallsSettings = lazy(
  () => import("../components/settings/bongaAdmin/CallsSettings")
);
const CrmSettings = lazy(
  () => import("../components/settings/bongaAdmin/CrmSettings")
);
const ChatDeskSettings = lazy(
  () => import("../components/settings/bongaAdmin/ChatdeskSettings")
);
const BusinessRules = lazy(
  () => import("../components/settings/bongaAdmin/BusinessRules")
);
const OrgDetails = lazy(
  () => import("../components/settings/organization/OrgDetails")
);
const Branches = lazy(
  () => import("../components/settings/organization/Branches")
);
const ViewMenu = lazy(() => import("../components/callModule/Menus/ViewMenu"));
const Users = lazy(() => import("../components/settings/organization/Users"));
const ApiApps = lazy(
  () => import("../components/settings/organization/ApiApps")
);
const Roles = lazy(() => import("../components/settings/organization/Roles"));
const NotFound = lazy(() => import("../pages/NotFound"));
const CallModule = lazy(() => import("../pages/CallModule"));
const CmOverview = lazy(() => import("../components/callModule/CmOverview"));
const CrmLiveReports = lazy(
  () => import("../components/live-reports/CrmLiveReports")
);
const CallMonitoring = lazy(
  () => import("../components/live-reports/voice-report/CallMonitoring")
);
const TicketingOverview = lazy(
  () => import("../components/chatDesk/TicketingOverview")
);
const CmReport = lazy(
  () => import("../components/callModule/Reports/CmReport")
);
const CmOverallAnalytics = lazy(
  () => import("../components/callModule/Analytics/CmOverallAnalytics")
);
const CrmFunnel = lazy(() => import("../components/crm/CrmFunnel"));
const Subscriptions = lazy(() => import("../pages/Subscriptions"));
const Wallets = lazy(() => import("../pages/Wallets"));
const WalletTransactions = lazy(() => import("../pages/WalletTransactions"));
const Settings = lazy(() => import("../pages/Settings"));
const Bot = lazy(() => import("../pages/Bot"));
const BotsOverview = lazy(() => import("../components/bots/BotsOverview"));
const Builder = lazy(() => import("../components/bots/Builder"));
const Catalog = lazy(() => import("../components/bots/components/Catalog"));
const Inventory = lazy(() => import("../components/bots/components/Inventory"));
const CommerceOverview = lazy(
  () => import("../components/bots/components/commerce/CommerceOverview")
);
const WhatsappTabs = lazy(
  () => import("../components/campaigns/WhatsApp/WhatsappTabs")
);

const moduleList = process.env.REACT_APP_MODULES?.split(",");
const homeRoute = process.env.REACT_APP_HOME_ROUTE;
const hasReports = process.env.REACT_APP_LIVEREPORTS === "true";

const hash = window?.location?.hash;

if (hash) {
  window.location.href = `/settings/bonga/chatdesk?${hash}`;
}

const allRoutes: RouteDataType[] = [
  {
    module: "DASHBOARD",
    path: "/",
    component: <Navigate to={homeRoute ?? "/"} />,
  },
  {
    module: "APPS",
    path: "/apps",
    component: <Apps />,
    children: [
      {
        path: "/",
        component: <Navigate to={"/apps/projects"} />,
      },
      // {
      //   path: "/calendar",
      //   component: <MyCalendar />,
      // },
      // {
      //   path: "/emails",
      //   component: <AccountEmails />,
      // },
      {
        path: "/projects",
        component: <ProjectsView />,
      },
      {
        path: "/projects/:id",
        component: <ProjectsView />,
      },
      {
        path: "/tasks",
        component: <TasksTable />,
      },
      {
        path: "tasks/:taskId",
        component: <ViewTask />,
      },
      {
        path: "/surveys",
        component: <Surveys />,
      },
      {
        path: "/surveys/:surveyId",
        component: <ViewQuestionnaire />,
      },
      {
        path: "/surveys/add_survey",
        component: <CreateQuestionnaire />,
      },
      {
        path: "/surveys/:surveyId/new_response",
        component: <SurveyResponse />,
      },
      {
        path: "/surveys/:surveyId/response_submitted",
        component: <SurveySubmitted />,
      },
      // {
      //   path: "/documents",
      //   component: <Documents />,
      // },
      // {
      //   path: "/document_editor",
      //   component: <DocEditor />,
      // },
    ],
  },
  {
    module: "CONTACTS",
    path: "/contacts",
    component: <ContactsTable />,
  },
  {
    module: "CONTACTS",
    path: "contacts/:contactId",
    component: <ViewContact />,
  },
  {
    module: "CAMPAIGNS",
    path: "/campaigns",
    component: <Campaigns />,
    children: [
      {
        path: "/",
        component: <Navigate to={"/campaigns/autodialer"} />,
      },
      {
        path: "/autodialer",
        component: (
          <PermissionsProvider
            permissionString="add_autodialer_campaign"
            altRender={true}
            altRenderMessage={strings.access_denied_message}
          >
            <AutoDialer />
          </PermissionsProvider>
        ),
      },
      {
        path: "/autodialer/:id",
        component: (
          <PermissionsProvider
            permissionString="add_autodialer_campaign"
            altRender={true}
            altRenderMessage={strings.access_denied_message}
          >
            <ViewDialCampaign />
          </PermissionsProvider>
        ),
      },
      {
        path: "/robotcall",
        component: (
          <PermissionsProvider
            permissionString="add_autodialer_campaign"
            altRender={true}
            altRenderMessage={strings.access_denied_message}
          >
            <RobotCall />
          </PermissionsProvider>
        ),
      },
      {
        path: "/robotcall/:id",
        component: (
          <PermissionsProvider
            permissionString="add_autodialer_campaign"
            altRender={true}
            altRenderMessage={strings.access_denied_message}
          >
            <ViewRobotCampaign />
          </PermissionsProvider>
        ),
      },
      {
        path: "/sms",
        component: <Sms />,
      },
      {
        path: "/sms/:id",
        component: <ViewSmsCampaign />,
      },
      {
        path: "whatsapp/:id",
        component: <ViewWhatsAppCampaign />,
      },
      {
        path: "/whatsapp",
        component: <WhatsappTabs />,
      },
    ],
  },
  {
    module: "CRM",
    path: "/crm",
    component: <Crm />,
    children: [
      {
        path: "/",
        component: <Navigate to={"/crm/overview"} />,
      },
      {
        path: "/admin",
        component: <Redirect to={"/settings/bonga/crm"} />,
      },
      {
        path: "/account",
        component: <Navigate to={"/crm/overview"} />,
      },
      {
        path: "/overview",
        component: <CrmOverview />,
      },
      {
        path: "/analytics",
        component: <CrmAnalytics />,
      },
      {
        path: "/account/:id",
        component: <CrmDetails />,
      },
      {
        path: "/funnel",
        component: <CrmFunnel />,
        children: [
          {
            path: "/",
            component: <Navigate to={"/crm/funnel/leads"} />,
          },
          {
            path: "/leads",
            component: <LeadsTable />,
            children: [
              {
                path: "/:leadId",
                component: <ViewLead />,
              },
            ],
          },
          {
            path: "/deals",
            component: <DealsTable />,
          },
        ],
      },
    ],
  },
  {
    module: "CHATDESK",
    path: "/chatdesk",
    component: <ChatDesk />,
    children: [
      {
        path: "/",
        component: <Navigate to={"/chatdesk/overview"} />,
      },
      {
        path: "/admin",
        component: <Redirect to={"/settings/bonga/chatdesk"} />,
      },
      {
        path: "/ticket",
        component: <Navigate to={"/chatdesk/overview"} />,
      },
      {
        path: "/overview",
        component: <TicketingOverview />,
      },
      {
        path: "/analytics",
        component: (
          <PermissionsProvider
            permissionString={"chatdesk_analytics"}
            altRender={true}
            altRenderMessage={strings.access_denied_message}
          >
            <TicketingAnalytics />
          </PermissionsProvider>
        ),
      },
      {
        path: "/reports",
        component: <TicketingReports />,
      },
      {
        path: "/help-desk",
        component: <TicketingHelpDesk />,
      },
      {
        path: "/ticket/:id",
        component: <TicketDetails />,
      },
    ],
  },
  {
    module: "LIVEREPORTS",
    path: "/live-reports",
    component: <CrmLiveReports />,

    children: [
      {
        path: "/",
        component: <SummaryReports />,
      },
      {
        path: "/summary-reports",
        component: <SummaryReports />,
      },
      {
        path: "/agent-reports",
        component: <AgentReport />,
      },
      {
        path: "/voice-reports",
        component: <VoiceReport />,
      },
    ],
  },
  {
    module: "CALLMODULE",
    path: "/calls",
    component: <CallModule />,
    children: [
      {
        path: "/",
        component: <Navigate to={"/calls/overview"} />,
      },
      {
        path: "/admin",
        component: <Redirect to={"/settings/bonga/calls"} />,
      },
      {
        path: "/overview",
        component: <CmOverview />,
      },
      {
        path: "/csat",
        component: (
          <PermissionsProvider
            permissionString={"view_csat_surveys"}
            altRender={true}
            altRenderMessage={strings.access_denied_message}
          >
            <CmCsat />
          </PermissionsProvider>
        ),
      },
      {
        path: "/csat/:id",
        component: <ViewSurvey />,
      },
      {
        path: "/analytics",
        component: <CmOverallAnalytics />,
      },
      {
        path: "/reports",
        component: <CmReport />,
      },
      {
        path: "/live-reports",
        component: hasReports ? (
          <CrmLiveReports />
        ) : (
          <Navigate to={"/calls/overview"} />
        ),
      },
      {
        path: "/live-reports/call-monitoring",
        component: hasReports ? (
          <CallMonitoring />
        ) : (
          <Navigate to={"/calls/overview"} />
        ),
      },
    ],
  },
  {
    module: "BOTBUILDER",
    path: "/bots",
    component: <Bot />,
    children: [
      {
        path: "/",
        component: <Navigate to={"/bots/overview"} />,
      },
      {
        path: "/overview",
        component: <BotsOverview />,
      },
      {
        path: "/builder",
        component: <Builder />,
      },
      {
        path: "/builder/:id",
        component: <Builder />,
      },
    ],
  },
  {
    module: "COMMERCE",
    path: "/commerce",
    component: <Commerce />,
    children: [
      {
        path: "/",
        component: <Navigate to={"/commerce/overview"} />,
      },
      {
        path: "/catalogs",
        component: <Catalog />,
      },

      {
        path: "/overview",
        component: <CommerceOverview />,
      },
      {
        path: "/catalogs/:id",
        component: <Inventory />,
      },
    ],
  },
  {
    module: "SUBSCRIPTIONS",
    path: "/billing",
    component: <Subscriptions />,
    children: [
      {
        path: "/",
        component: <Navigate to="wallets" />,
      },
      // {
      //   path: "/subscriptions/preferences",
      //   component: <SubscriptionPreferences />,
      // },
      // {
      //   path: "/subscriptions/history",
      //   component: <SubscriptionHistory />,
      // },
      // {
      //   path: "/subscriptions/invoices",
      //   component: <SubscriptionInvoices />,
      // },
      // {
      //   path: "/subscriptions/invoices/:id",
      //   component: <div>Invoice Detail</div>,
      // },
      {
        module: "WALLETS",
        path: "/wallets",
        component: <WalletContextProvider />,
        children: [
          {
            path: "/",
            component: <Wallets />,
          },
          {
            path: "/transactions/:id",
            component: <WalletTransactions />,
          },
        ],
      },
    ],
  },

  {
    module: "SETTINGS",
    path: "/settings",
    component: <Settings />,
    children: [
      {
        path: "/",
        component: <Navigate to={"my/profile"} />,
      },
      {
        path: "/my/profile",
        component: <ProfileSettings />,
      },
      {
        path: "/my/password",
        component: <PasswordSettings />,
      },
      {
        path: "/bonga/calls",
        component: <CallsSettings />,
      },
      {
        path: "/bonga/calls/menu/:id",
        component: <ViewMenu />,
      },
      {
        path: "/bonga/crm",
        component: <CrmSettings />,
      },
      {
        path: "/bonga/chatdesk",
        component: <ChatDeskSettings />,
      },
      {
        path: "/bonga/business_rules",
        component: <BusinessRules />,
      },
      {
        path: "/org/details",
        component: <OrgDetails />,
      },
      {
        path: "/org/users",
        component: <Users />,
      },
      {
        path: "/org/api_apps",
        component: <ApiApps />,
      },
      {
        path: "/org/users/:id",
        component: <UserProfile />,
      },
      {
        path: "/org/roles",
        component: <Roles />,
      },
      {
        path: "/org/roles/:id",
        component: <ViewRole />,
      },
      {
        path: "/org/branches",
        component: <Branches />,
      },
      {
        path: "/org/departments",
        component: <Departments />,
      },
    ],
  },
  {
    module: "NOTFOUND",
    path: "*",
    component: <NotFound />,
  },
].filter((route: any) => {
  return (moduleList ?? []).includes(route.module);
});

export default allRoutes;
