import { lazy } from "react";
import { RouteDataType } from "../types/DataTypes";

const Surveys = lazy(() => import("../components/surveys/Surveys"));
const ViewQuestionnaire = lazy(
  () => import("../components/surveys/ViewQuestionnaire")
);
const CreateQuestionnaire = lazy(
  () => import("../components/surveys/questionnaire/CreateQuestionnaire")
);
const SurveyResponse = lazy(
  () => import("../components/surveys/responses/SurveyResponse")
);
const SurveySubmitted = lazy(
  () => import("../components/surveys/responses/SurveySubmitted")
);

const Phone = lazy(() => import("../components/phone/phone"));
const ForgotPassword = lazy(() => import("../pages/ForgotPassword"));
const Login = lazy(() => import("../pages/Login"));
const SignUp = lazy(() => import("../pages/SignUp"));
const WebChat = lazy(() => import("../pages/WebChat"));

const authRoutes: RouteDataType[] = [
  {
    path: "/login",
    component: <Login />,
  },
  {
    path: "/register",
    component: <SignUp />,
  },
  {
    path: "/forgot-password",
    component: <ForgotPassword />,
  },
  {
    path: "/da-dialer",
    component: <Phone />,
  },
  {
    path: "/webchat",
    component: <WebChat />,
  },
  {
    path: "/surveys",
    component: <Surveys />,
  },
  {
    path: "/surveys/:surveyId",
    component: <ViewQuestionnaire />,
  },
  {
    path: "/surveys/add_survey",
    component: <CreateQuestionnaire />,
  },
  {
    path: "/surveys/:surveyId/sharing",
    component: <SurveyResponse />,
  },
  {
    path: "/surveys/:surveyId/response_submitted",
    component: <SurveySubmitted />,
  },
];

export default authRoutes;
