import React, { ReactNode, useContext } from "react";
import {
	Box,
	FormControl,
	HStack,
	SelectProps,
	chakra,
} from "@chakra-ui/react";
import { FormContext } from "./FormContainer";
import ErrorContainer from "../ErrorContainer";
import { Select } from "antd";
import { borderColor, primaryWhite } from "../../../theme/theme";

interface Props extends SelectProps {
	name: string;
	optionalCallback?: (id: number | string) => void;
	setState?: React.Dispatch<React.SetStateAction<boolean>>;
	stateString?: string;
	action?: ReactNode;
	defaultValue?: any;
	data?: any[];
	onDataChange?: (value: string) => void;
	withLabel?: boolean;
	label?: string;
	[key: string]: any;
}

function FormSelect({
	optionalCallback,
	action,
	name,
	placeholder,
	isRequired,
	children,
	setState,
	stateString,
	defaultValue,
	data,
	handleSearch,
	onDataChange,
	withLabel,
	label,
	...rest
}: Props) {
	const formContext = useContext(FormContext);
	const { initialValues, register, errors, setValue } = formContext;

	if (register == null && !rest.register) {
		throw new Error(
			"This component must be used in the context of a <FormContainer> component"
		);
	}
	const handleOnChange = (value: string) => {
		if (setValue) setValue(name, value);
		if (optionalCallback) optionalCallback(value);
		if (setState) stateString === value && setState(true);
		if (setState) stateString !== value && setState(false);
		onDataChange && onDataChange(value);
	};

	const handleOnSearch = (value: string) => {
		handleSearch && handleSearch(value);
	};

	return (
		<ErrorContainer error={errors[name]}>
			<FormControl>
				{withLabel && (
					<HStack
						alignItems={"flex-end"}
						justifyContent={"flex-start"}
						w={"100%"}
						pl={"1rem"}
					>
						<Box bg={primaryWhite} mb={"-10px"} zIndex={100} p={"0 .5rem"}>
							<chakra.label
								htmlFor={name}
								textTransform={"capitalize"}
								color={"gray.500"}
							>
								{label ? label.replaceAll("_", " ") : name.replaceAll("_", " ")}
							</chakra.label>
						</Box>
					</HStack>
				)}
				<HStack>
					<Select
						showSearch
						style={{
							width: "100%",
							border: `1px solid ${borderColor}`,
							height: "32px",
						}}
						defaultValue={initialValues[name] ?? defaultValue}
						placeholder={placeholder ?? `Select ${name}`}
						optionFilterProp={name}
						filterOption={(input, option) =>
							JSON.stringify(option).toLowerCase().includes(input.toLowerCase())
						}
						onSelect={handleOnChange}
						onSearch={handleOnSearch}
					>
						{children}
					</Select>
					{action}
				</HStack>
			</FormControl>
		</ErrorContainer>
	);
}

export default FormSelect;
