import { Box, Divider, Flex, Heading, HStack, Spacer, Tag, Text, useDisclosure, Wrap } from "@chakra-ui/react";
import React from "react";
import { useDrag } from "react-dnd";
import moment from "moment";
import { BiCalendarStar } from "react-icons/bi";
import { CrmTaskDataType } from "../../../types/DataTypes";
import TaskAgents from "../tasks/TaskAgents";
import TaskModal from "../tasks/TaskModal";

function ListBoardEntry({ task }: any) {
    const [{ isDragging }, drag] = useDrag(() => ({
        type: "task_entry",
        item: { task: task },
        collect: (monitor) => ({
            isDragging: !!monitor.isDragging(),
        }),
    }));
    const [isHovering, setIsHovering] = React.useState(false);
    const [open, setOpen] = React.useState(false);


    return (
        <Flex
            ref={drag}
            cursor={"pointer"}
            key={task?.id}
            outline="none"
            border="1px solid #f0f0f0"
            bg={isDragging ? "#f0f0f0" : "white"}
            mt={".5rem"}
            minH={"5rem"}
            borderRadius={"3px"}
            p={".5rem"}
            flexDirection={"column"}
            gap={".3rem"}
            role={"details"}
            onMouseEnter={() => setIsHovering(true)}
            onMouseLeave={() => setIsHovering(false)}
            onClick={() => { setOpen(true) }}
            width={"100%"}
        >
            <Flex
                flexDirection={"column"}
                justifyContent={"space-between"}
                alignItems={"center"}
                gap={".2rem"}
            >
                {isHovering && (
                    <Flex gap={".2rem"} justifyContent={"center"} alignItems={"center"}>
                        {/* <EditTask initialValues={task} type="task" /> */}
                        {/* <ArchiveTask task={task} /> TODO: Add ArchiveTask */}
                    </Flex>
                )}
            </Flex>
            <Text fontWeight={"600"}
                textTransform="capitalize"
                alignSelf={"flex-start"}
            >
                {task?.title}
            </Text>

            <Text color={"gray"}>{task?.note}</Text>
            <Flex alignItems={"center"} gap={".5rem"}>
                <Tag
                    borderRadius={"3px"}
                    colorScheme={
                        moment(task?.dueDate).isBefore(new Date()) ? "red" : "green"
                    }
                    size={"xs"}
                    p={".2rem .5rem"}
                >
                    <Flex gap={".3rem"} alignItems={"center"}>
                        <BiCalendarStar />
                        {`${moment(task?.dueDate).format("MMM DD, YYYY - hh:mm A")}`}
                    </Flex>
                </Tag>
                <Box ml={".5rem"}>
                    <TaskAgents<CrmTaskDataType>
                        task={task}
                        type={"task"}
                        agentIds={task?.assigned_to}
                    />
                </Box>
            </Flex>
            {/* <Threads id={task?.id} type="task" /> */}
            <TaskModal task={task} open={open} />

        </Flex>
    );
}

export default ListBoardEntry;
