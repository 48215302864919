import {
  chakra,
  HStack,
  ResponsiveValue,
  SimpleGrid,
  Text,
  useId,
} from "@chakra-ui/react";
import React, {
  BaseSyntheticEvent,
  ReactElement,
  useEffect,
  useRef,
  useState,
} from "react";
import { useForm, UseFormRegister } from "react-hook-form";
import {
  Control,
  SubmitHandler,
  UseFormSetValue,
} from "react-hook-form/dist/types/form";
import { FcSurvey } from "react-icons/fc";
import { useDispatch } from "react-redux";
import { gray_400 } from "../../../theme/theme";
import { onInputHover } from "../CustomInput";

interface FormContextProps {
  initialValues: { [key: string]: any };
  errors: { [key: string]: string };
  register?: UseFormRegister<any>;
  setValue?: UseFormSetValue<any>;
  control?: Control<any>;
  hover: any;
}

export const FormContext = React.createContext<FormContextProps>({
  initialValues: {},
  errors: {},
  hover: {},
});

interface Props {
  columns?: ResponsiveValue<number>;
  initialValues?: { [key: string]: any };
  errors?: { [key: string]: any };
  children?: any;
  onMessageUpdate?: boolean;
  footer?: ReactElement;
  noFooter?: boolean;
  spacing?: string | number;
  onSubmit: SubmitHandler<any>;
  [key: string]: any;
}

function FormContainer(props: Props) {
  return <FormContainerInner {...props} children={props.children} />;
}

function FormContainerInner({
  columns = 2,
  spacing = ".5rem",
  onMessageUpdate,
  onSubmit,
  initialValues = {},
  children,
  footer,
  noFooter,
  errors = {},
}: Props) {
  const { register, handleSubmit, setValue, control, reset } = useForm();
  const dispatch = useDispatch();
  const [values, setValues] = useState<any>(initialValues ?? {});
  const formKey = useId();
  const blockerKey = useId();

  const formRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!!formRef) {
      formRef?.current?.addEventListener("submitData", (e: any) => {
        setValues((prevState: any) => ({
          ...prevState,
          [`${e.detail.form}`]: e.detail.data.id,
        }));
      });
    }
  }, [formRef]);

  const onHandleSubmit: SubmitHandler<any> = (
    values: any,
    event?: BaseSyntheticEvent
  ) => {
    event?.stopPropagation();
    if (event?.target?.id !== `${formKey}`) return;
    onSubmit(values);
    reset();
  };

  return (
    <FormContext.Provider
      value={{
        initialValues: values,
        errors: errors,
        register: register,
        control: control,
        setValue: setValue,
        hover: onInputHover,
      }}
    >
      <chakra.form
        key={formKey}
        id={formKey}
        onSubmit={handleSubmit(onHandleSubmit)}
        width={"100%"}
      >
        <SimpleGrid
          id={blockerKey}
          ref={formRef}
          columns={columns}
          spacing={spacing}
        >
          {children}
        </SimpleGrid>
      </chakra.form>
      {footer}
      {!noFooter && (
        <HStack justifyContent={"center"} alignItems={"center"}>
          <Text>
            Powered by{" "}
            <Text color={"gray.500"} as={"span"} fontWeight={"bold"}>
              Bonga
              <FcSurvey color={gray_400} />
              Forms
            </Text>
          </Text>
        </HStack>
      )}
    </FormContext.Provider>
  );
}

export default FormContainer;
