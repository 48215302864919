import { Button, ButtonProps, Tooltip } from "@chakra-ui/react";
import { TbCopy } from "react-icons/tb";

interface CustomIconButtonProps extends ButtonProps {
	tooltipLabel?: string;
	hasArrow?: boolean;
	placement?: "top" | "bottom" | "left" | "right";
	[key: string]: any;
}

function CustomCopyButton(props: CustomIconButtonProps) {
	const {
		tooltipLabel,
		hasArrow,
		placement,
		colorScheme,
		color,
		width,
		children,
		loading,
		loadingText,
		onClick,
		...rest
	} = props;
	return (
		<Tooltip label={tooltipLabel} placement={placement??"bottom"} hasArrow={hasArrow}>
            <Button
                placeItems={"center"}
                bg={"gray.200"}
                borderRadius={"3px"}
                _hover={{bg: "gray.300"}}
                color={"gray.700"}
                size={"xs"}
                onClick={onClick}
                {...rest}
            >
				<TbCopy />
			</Button>
		</Tooltip>
	);
}

export default CustomCopyButton;
