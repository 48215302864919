import { Flex, HStack, Heading, Spacer, Wrap, Box, Text, Tag, useDisclosure } from "@chakra-ui/react";
import { Divider } from "antd";
import moment from "moment";
import { BiCalendarStar } from "react-icons/bi";
import { Task } from "../../../gateway/gRPC/pb/task_pb";
import strings from "../../../localization/main";
import { primaryWhite, borderColor } from "../../../theme/theme";
import AddTaskLabel from "../../crm/tasks/labels/AddTaskLabel";
import TaskLabels from "../../crm/tasks/labels/TaskLabels";
import MyModal from "../../general/MyModal";
import { SubHeading } from "../../headings/Headings";
import CustomViewButton from "../../buttons/CustomViewButton";
import { useEffect } from "react";

interface TaskModalProps {
    task: Task.AsObject;
    tableView?: boolean;
    open?: boolean;
}

const TaskModal = ({ task, tableView, open }: TaskModalProps) => {
    const { onOpen, isOpen, onClose } = useDisclosure();
    useEffect(() => {
        if (open) {
            onOpen();
        }
    }, [open]);

    return (
        <>
            {
                tableView && (
                    <CustomViewButton
                        tooltipLabel={"view task"}
                        onClick={onOpen} />
                )
            }

            <MyModal
                size={"xl"}
                closeOnOverlayClick={false}
                disclosure={{ isOpen, onClose, onOpen }}
                scrollBehavior={"inside"}
                title={task?.title}
            >
                <Flex
                    gap={".5rem"}
                    w={"100%"}
                    direction={"column"}
                    p={"1rem"}
                    bg={primaryWhite}
                    borderRadius={"3px"}
                    border={`1px solid ${borderColor}`}
                >
                    <HStack
                        w={"100%"}
                        justify={"space-between"}
                        alignItems={"center"}
                        gap={".5rem"}
                        borderRadius={"3px"}
                        p={"0"}
                    >
                        <Spacer />
                        {/* <TaskAgents
                        task={task}
                        type={"task"}
                        agentIds={task?}
                    /> */}
                        {/* <Wrap spacing={".2rem"}>
                        <TaskLabels labelIds={task?.labelsList} accountId={task?.account} />
                        <AddTaskLabel task={task} labelIds={task?.label} />
                    </Wrap> */}
                        {/* <TaskMenu type={"task"} task={task} /> */}
                    </HStack>
                    <Box w={"100%"}>
                        <SubHeading>{strings.notes}</SubHeading>
                        <Text fontSize={"sm"}>{task?.note}</Text>
                    </Box>
                    <Divider />
                    <Tag
                        borderRadius={"3px"}
                        colorScheme={
                            moment(task.dueDate).isBefore(new Date()) ? "red" : "green"
                        }
                        // size={"xs"}
                        p={".2rem .5rem"}
                        w={"max-content"}
                    >
                        <Flex gap={".3rem"} alignItems={"center"}>
                            <BiCalendarStar />
                            {`${moment(task?.dueDate).format("MMM DD, YYYY - hh:mm A")}`}
                        </Flex>
                    </Tag>
                    {/* <Divider />
                <Links task={task} />
                <Divider />
                <SubTasks record={task} />
                <Divider />
                <Threads id={task?.id} type="task" /> */}
                </Flex>
            </MyModal>
        </>
    )
};

export default TaskModal;