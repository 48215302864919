import { assign } from "lodash";
import { CalendarLabel } from "../pb/calendar_label_pb";
import { Event } from "../pb/event_pb";
import { List } from "../pb/list_pb";
import { Project } from "../pb/project_pb";
import { Assignee, Link, Task } from "../pb/task_pb";

export const projectJsonToClass = (projectJson: Project.AsObject): Project => {
	const project = new Project();
	project.setId(projectJson.id);
	project.setTitle(projectJson.title);
	project.setDescription(projectJson.description);
	project.setVisibility(projectJson.visibility);

	return project;
};

export const taskJsonToClass = (taskJson: Task.AsObject): Task => {
	const task = new Task();

	const labels: CalendarLabel[] = [];
	const links: Link[] = [];
	const assignees: Assignee[] = [];

	const labelsList = taskJson.labelsList;
	const linksList = taskJson.linksList;
	const assigneeList = taskJson.assigneesList;

	labelsList?.forEach((label) => {
		labels.push(calendarLabelJsonToClass(label));
	});

	linksList?.forEach((link) => {
		links.push(linkJsonToClass(link));
	});

	// assigneeList?.forEach((assignee) => {
	// 	assignees.push(assigneeJsonToClass(assignee));
	// });

	assigneeList?.forEach((assignee) => {
		assignees.push(new Assignee().setId(assignee.id));
	});

	task.setId(taskJson.id);
	task.setTitle(taskJson.title);
	task.setListId(taskJson.listId);
	task.setDueDate(taskJson.dueDate);
	task.setScope(taskJson.scope);
	task.setNote(taskJson.note);
	task.setLabelsList(labels);
	task.setLinksList(links);
	task.setAssigneesList(assignees);

	return task;
};

export const calendarLabelJsonToClass = (
	labelJson: CalendarLabel.AsObject
): CalendarLabel => {
	const calendarLabel = new CalendarLabel();
	calendarLabel.setId(labelJson.id);
	calendarLabel.setTitle(labelJson.title);
	calendarLabel.setDescription(labelJson.description);

	return calendarLabel;
};

export const eventJsonToClass = (eventJson: Event.AsObject): Event => {
	const event = new Event();
	event.setId(eventJson.id);
	event.setTitle(eventJson.title);
	event.setLocation(eventJson.location);
	event.setConferencing(eventJson.conferencing);
	event.setStartDate(eventJson.startDate);
	event.setEndDate(eventJson.endDate);
	event.setNote(eventJson.note);
	event.setScope(eventJson.scope);

	return event;
};

export const listJsonToClass = (listJson: List.AsObject): List => {
	const list = new List();
	list.setId(listJson.id);
	list.setTitle(listJson.title);
	list.setProjectId(listJson.projectId);
	list.setDescription(listJson.description);

	return list;
};

export const linkJsonToClass = (linkJson: Link.AsObject): Link => {
	const link = new Link();
	link.setType(linkJson.type);
	link.setUuid(linkJson.uuid);

	return link;
};

export const assigneeJsonToClass = (
	assigneeJson: Assignee.AsObject
): Assignee => {
	const assignee = new Assignee();
	assignee.setId(assigneeJson.id);

	return assignee;
};
