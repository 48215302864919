import {
	Box,
	chakra,
	FormControl,
	HStack,
	TextareaProps,
} from "@chakra-ui/react";
import { useContext } from "react";
import CustomTextArea from "../CustomTextArea";
import ErrorContainer from "../ErrorContainer";
import { FormContext } from "./FormContainer";
import { primaryWhite } from "../../../theme/theme";

interface Props extends TextareaProps {
	name: string;
	withLabel?: boolean;
	label?: string;
	[key: string]: any;
}

function FormTextArea(props: Props) {
	const {
		register: propRegister,
		isRequired,
		onChange,
		name,
		placeholder,
		withLabel,
		label,
	} = props;
	const formContext = useContext(FormContext);
	const { initialValues, register, errors, hover } = formContext;
	if (register == null && !props.register) {
		throw new Error(
			"This component must be used in the context of a <FormContainer> component"
		);
	}
	const options = { required: isRequired, onChange: onChange };
	return (
		<ErrorContainer error={errors[props.name]}>
			<FormControl>
				{withLabel && (
					<HStack
						alignItems={"flex-end"}
						justifyContent={"flex-start"}
						w={"100%"}
						pl={"1rem"}
					>
						<Box bg={primaryWhite} mb={"-10px"} zIndex={100} p={"0 .5rem"}>
							<chakra.label
								htmlFor={name}
								textTransform={"capitalize"}
								color={"gray.500"}
							>
								{label ? label.replaceAll("_", " ") : name.replaceAll("_", " ")}
							</chakra.label>
						</Box>
					</HStack>
				)}
				<CustomTextArea
					bg={"transparent"}
					border={"1px"}
					defaultValue={`${initialValues[name] ?? ""}`}
					placeholder={`${placeholder}`}
					{...props}
					{...(register
						? register(name, { ...options })
						: propRegister(name, { ...options }))}
					boxShadow={"none"}
				/>
			</FormControl>
		</ErrorContainer>
	);
}

export default FormTextArea;
